<template>
  <v-card elevation="0" class="bar-status pa-0 my-4" :class="barClass">
    <v-container class="pa-0">
      <v-row no-gutters class="align-center">
        <v-col cols="4">
          <div class="py-2 pl-3 d-flex justify-start align-center">
            {{ title }}：
          </div>
        </v-col>
        <v-col cols="8">
          <div class="py-2 pr-3 d-flex align-center" :class="{'justify-center': !readonly, 'justify-end': readonly}">
            <div v-if="readonly">{{ data | fixedPrice(fixed) }}</div>
            <number-input
              v-else
              v-model="data"
              :reload="reload"
              :control="control"
              :name="name"
              :fixed="fixed"
              :rule="rule"
              @reload="onReload"
              @change="onChange"
            />
            <p class="mb-0 ml-3 d-block">{{ unit }}</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import mixins from "./mixins"
export default {
  mixins: [mixins],
  props: {
    value: {
      type: [String, Number],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    unit: {
      type: String,
    },
    control: {
      type: Boolean,
      default: true,
    },
    reload: {
      type: Boolean,
      default: false,
    },
    name: String,
    fixed: {
      type: Number,
      default: 0
    },
    rule: {
      type: Function,
      default: () => null
    },
  },
  components: {
    "number-input": () => import("@/modules/base/components/numberInput.vue"),
  },
  data: () => ({
    data: 0,
  }),
  watch: {
    value: {
      immediate: true,
      handler() {
        this.data = this.value;
      },
    },
    data() {
      this.$emit("input", this.data);
    },
  },
  methods: {
    onReload() {
      this.$emit("reload");
    },
    onChange(value) {
      this.$emit("change", value);
    },
  },
};
</script>
<style lang="sass">
.bar-number
  background-color: #FCFCFC !important
  border: 1px solid #EFEFEF !important
  color: #32383E !important

</style>
