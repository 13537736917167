export default {
  props: {
    type: {
      type: String,
      default: 'primary',
      validator(value) {
        return ['primary', 'secondary', 'error','outlined', 'success', 'mine'].includes(value)
      }
    }
  },
  computed: {
    barClass() {
      let className = 'bar-number'
      if(this.type == 'primary') return className += ' statusA'
      if(this.type == 'secondary') return className += ' statusB'
      if(this.type == 'outlined') return className += ' statusC'
      if(this.type == 'error') return className += ' statusD'
      if(this.type == 'success') return className += ' statusE'
      if(this.type == 'mine') return this.type
      return ''
    },
  },
}